import { useReactiveVar } from '@apollo/client';
import { storyEditorDataVar } from '@/graphql/cache';

/**
 * @returns {{settingsUrl:string,channelsUrl:string,editUrl:string,reviewEndpoint:string,exportPdfEndpoint:string,exportDocxEndpoint:string,exportZipEndpoint:string,fetchEndpoint:string}}
 */
export default function useStoryEditorData() {
  const data = useReactiveVar(storyEditorDataVar);

  return { ...data };
}
